<template>
  <v-container fluid>
    <v-row v-if="machineStatus === 'enroll'" class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" v-bind:src="pageIconURL" />
              </div>
              <h1 class="reset-password-title">Unauthorized Machine</h1>
              <p class="reset-password-subtitle">The system detected that your current machine is unauthorized</p>

              <!-- Enroll Machine Button -->
              <v-col fluid cols="12" class="ph-action-buttons">
                <v-col fluid cols="12" class="ph-action-button">
                  <v-btn
                    x-large
                    color="#1A2791"
                    elevation="0"
                    class="ph-request-reset-button ph-login-button ph-login-reset-btn"
                    @click="enrollMachine"
                  >
                    Enroll Machine
                  </v-btn>
                </v-col>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>

    <v-row v-else-if="machineStatus === 'activate'" class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" v-bind:src="pageIconURL" />
              </div>
              <h1 class="reset-password-title">Activate Machine</h1>
              <p class="reset-password-subtitle">Enter the activation key for <strong>{{ remarks || 'this machine.' }}</strong><v-btn small icon @click="copyText"><v-icon small>mdi-clipboard-text-outline</v-icon></v-btn></p>
              <!-- Input Field for Activation Code -->
              <v-col fluid cols="12" class="ph-textbox-container">
                <v-text-field
                  v-model="activationKey"
                  type="text"
                  placeholder="Activation Code"
                  hide-details
                  outlined
                  class="ph-textbox"
                  :error="(!activationKey && hasProcessEnroll)"
                ></v-text-field>
              </v-col>

              <!-- Activate Machine Button -->
              <v-col fluid cols="12" class="ph-action-buttons">
                <v-col fluid cols="12" class="ph-action-button">
                  <v-btn
                    x-large
                    color="#1A2791"
                    elevation="0"
                    class="ph-request-reset-button ph-login-button ph-login-reset-btn"
                    @click="activateMachine"
                  >
                    Activate Machine
                  </v-btn>
                </v-col>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { renderToast } from '@/utils'
import { format } from 'date-fns'

export default {
  name: 'EnrollMachine',
  data () {
    return {
      pageIconURL: require('@/assets/images/icons/lock-icon.svg'),
      macAddress: '',
      cpu: '',
      hashCode: '',
      ipAddress: '',
      remarks: '',
      activationKey: '',
      hasProcessEnroll: false,
      machineStatus: '',
      showLightappError: false
    }
  },
  created () {
    this.checkMachineStatus()
    this.getIPAddress()
  },
  methods: {
    // GetTerminal
    async checkMachineStatus () {
      if (this.deviceType !== 'mobile') {
        this.$store.commit('SET_LOADING', true)
        const url = 'https://petnetusp.perahub.com.ph:8085/GetTerminal'
        try {
          const response = await fetch(url, { method: 'GET' })
          if (!response.ok) throw new Error('Request failed')

          const data = await response.json()

          switch (data._messageCode) {
            case '000':
              this.$router.push('/')
              break
            case '001':
            case '003':
              this.machineStatus = 'activate'
              break
            case '002':
              this.machineStatus = 'enroll'
              break
          }
        } catch (error) {
          renderToast('error', 'Connection Error', 'Unable to establish a secure connection with the lightapp')
        } finally {
          this.$store.commit('SET_LOADING', false)
        }
      }
    },
    async copyText () {
      try {
        await navigator.clipboard.writeText(this.remarks)
        renderToast('success', 'Success!', 'Copied to clipboard')
      } catch (error) {
        renderToast('error', 'Unable to copy', 'Due to browser restriction')
      }
    },
    // IP Address
    async getIPAddress () {
      try {
        const response = await fetch('https://api64.ipify.org?format=json')
        const data = await response.json()
        this.ipAddress = data.ip
      } catch (error) {
        renderToast('error', 'Network Error', 'Error fetching IP address. Please check your internet connection.')
      }
    },
    // Request Key
    generateRequestKey () {
      const randomKey = Array.from(Array(6), () => Math.floor(Math.random() * 36).toString(36)).join('').toUpperCase()
      const dateToday = format(new Date(), 'yyMMdd')
      return `PC${dateToday}-${randomKey}`
    },
    // Machine Enrollment
    async enrollMachine () {
      this.hasProcessEnroll = true
      const autoGeneratedRemarks = this.generateRequestKey()
      this.remarks = autoGeneratedRemarks

      try {
        // Fetch Machine Details
        const detailsResponse = await fetch('https://petnetusp.perahub.com.ph:8085/MachineEnroll?action=enroll')
        const detailsData = await detailsResponse.json()

        switch (detailsData._messageCode) {
          case '001':
            renderToast('success', 'Enrollment Successful:', 'Machine is already enrolled! Proceed to activation')
            this.machineStatus = 'activate'
            break
          case '002':
            this.Register(detailsData)
            break
        }
      } catch (error) {
        renderToast('error', 'Enrollment failed:', 'An error occurred while enrolling the machine. Please try again.')
      }
    },
    // Machine Activation
    async activateMachine () {
      const [hash, locationId] = this.activationKey.split('|')
      if (!hash || !locationId) {
        this.hasProcessEnroll = true
        renderToast('warning', 'Invalid Input', 'Please enter valid activation key.')
        return
      }

      try {
        const payload = {
          status: '4',
          id: '0',
          hash,
          activated_by: 10497,
          location_id: locationId,
          tpa_id: '',
          fs_id: ''
        }

        console.log('Activating Machine with Payload:', payload)

        const activateResponse = await fetch('https://apiman.perahub.com.ph/uat/usp-auth/v1/api/machine/update-status', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-perahub-key': 'eMztFvKCKucWzVDkcrusCLAGgP387zrVtULhrct9XbIuDWBL'
          },
          body: JSON.stringify(payload)
        })

        const activateData = await activateResponse.json()

        if (activateData.code === 200) {
          renderToast('success', 'Activation Successful', activateData.message)
          this.Activate('activate')
        } else {
          renderToast('error', 'Activation Failed', activateData.message || 'Unable to activate machine.')
        }
      } catch (error) {
        renderToast('error', 'Activation Failed', 'Error activating machine. Try again.')
      }
    },
    async Activate (_url) {
      try {
        const detailsResponse = await fetch('https://petnetusp.perahub.com.ph:8085/MachineEnroll?action=' + _url + '&h1=' + this.activationKey)

        const detailsData = await detailsResponse.json()

        switch (detailsData._messageCode) {
          case '000':
            this.$router.push('/')
            break
          case '001':
          case '003':
            this.machineStatus = 'activate'
            break
          case '004':
            this.machineStatus = 'enroll'
            break
        }
      } catch (error) {
        console.log(error)
        renderToast('error', 'Activation Failed', 'Error activating machine. Try again.')
      }
    },
    async Register (detailsData) {
      this.macAddress = detailsData._m1
      this.cpu = detailsData._c1
      this.hashCode = detailsData._h1

      // Proceed with Enrollment
      const payload = {
        mac_address: this.macAddress,
        cpu: this.cpu,
        ip_address: this.ipAddress,
        remarks: this.remarks
      }

      console.log('Enrolling Machine with Payload:', payload)

      const response = await fetch('https://apiman.perahub.com.ph/uat/usp-auth/v1/api/machine/enroll-machine', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-perahub-key': 'eMztFvKCKucWzVDkcrusCLAGgP387zrVtULhrct9XbIuDWBL'
        },
        body: JSON.stringify(payload)
      })

      const data = await response.json()

      console.log(data)

      if (data.code === 201) {
        this.Activate('enrollsucc')
      } else if (data.code === 200) {
        this.machineStatus = 'activate'
        this.remarks = data.result.remarks
      } else {
        renderToast('error', 'Enrollment failed:', data.message)
      }
    }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

@media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }
  }
</style>

export default {
  isLoading: state => state.isLoading,
  isAuthenticated: state => state.isAuthenticated,
  isOTPpassed: state => state.isOTPpassed,
  runSession: state => state.runSession,
  currUser: state => state.currUser,
  authTimestamp: state => state.authTimestamp,
  snackbar: state => state.snackbar,
  deviceType: state => state.deviceType,
  l1: state => state.l1,
  h1: state => state.h1
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/Index.vue'
import Login from '@/views/Login.vue'
import VerifyOneTimeCode from '@/views/VerifyOneTimeCode.vue'
import ForgetPassword from '@/views/ForgetPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import MachineRegister from '@/views/MachineRegister.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: HomeView
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/verify-otp-code',
    name: 'VerifyOneTimeCode',
    component: VerifyOneTimeCode
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/machine-register',
    name: 'MahchineRegister',
    component: MachineRegister
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" src="@/assets/images/icons/lock-icon.svg" />
              </div>
              <h1 class="reset-password-title">Two-factor Authentication</h1>
              <p class="reset-password-subtitle">Input your 6-digit One-time password PIN from your authenticator app.</p>
              <v-col fluid cols="12" class="ph-textbox-container ph-otp-input-container">
                <v-text-field
                  v-for="index in 6"
                  v-model="otp_code[index - 1]"
                  :error="(!hasOTPCode && hasProcessOTP) ? true : false"
                  :key="index"
                  :ref="'otpTxt' + index"
                  @paste="getCode($event,index)"
                  @keyup="tabSwitch(index)"
                  @keyup.enter="verifyOTP"
                  type="number"
                  placeholder="-"
                  hide-details
                  outlined
                  maxlength="1"
                  hide-spin-buttons
                  class="ph-textbox ph-otp-textbox"
                ></v-text-field>
              </v-col>
              <v-col fluid cols="12" class="ph-action-buttons">
                <v-col fluid cols="12" class="ph-action-button">
                  <v-btn ref="verifyBtn" @click="verifyOTP" x-large color="#1A2791" elevation="0" class="ph-login-button ph-login-reset-btn">
                    Verify OTP
                  </v-btn>
                </v-col>
                <v-col fluid cols="12" class="ph-back-button-container">
                  <router-link @click.native="backToLoginPage" to="/verify-otp-code" class="ph-back-button">
                    <img class="ph-back-logo" src="@/assets/images/icons/caret-back.svg" />
                    <p class="ph-back-text">Back to Login</p>
                  </router-link>
                </v-col>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>

    <!-- Reliever Confirmation Modal -->
    <v-dialog v-model="relieverDialog" persistent width="500">
      <v-card>
        <v-card-title class="ph-dialog-title">
          Reliever Confirmation
        </v-card-title>
        <v-card-text class="ph-dialog-header">
          <p>The system detected that you're not assigned to this location.</p>
          <p><strong>Are you a reliever?</strong></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" @click="confirmReliever" text>Yes</v-btn>
          <v-btn color="error" @click="cancelReliever" text>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import { format } from 'date-fns'
import { renderToast, aesEncrypt } from '@/utils'

export default {
  name: 'VerifyOneTimeCode',
  data () {
    return {
      otp_code: ['', '', '', '', '', ''],
      hasOTPCode: '',
      hasProcessOTP: false,
      getYear: format(new Date(), 'yyyy'),
      relieverDialog: false
    }
  },
  computed: {
    ...mapGetters({
      currUser: 'currUser',
      isAuthenticated: 'isAuthenticated',
      isOTPpassed: 'isOTPpassed',
      authTimestamp: 'authTimestamp'
    })
  },
  beforeCreate () {
    if (this.currUser === null) {
      this.$router.push('/login')
    }
  },
  created () {
    if (this.isOTPpassed) {
      location.href = this.$uspMainAppURL
    } else if (!this.isAuthenticated) {
      this.$router.push('/login')
    } else {
      this.$store.commit('SET_OTP_PASSED', false)
    }
  },
  mounted () {
    if (localStorage.getItem('otp_required_message')) {
      renderToast('warn', 'OTP not verified', localStorage.getItem('otp_required_message'))
    }
    this.$store.commit('SET_LOADING', false)
    setTimeout(() => {
      localStorage.removeItem('otp_required_message')
    }, 3500)
  },
  methods: {
    backToLoginPage () {
      this.$store.commit('SET_CURRENT_USER', null)
      this.$store.commit('SET_AUTHENTICATED', false)
      this.$router.push('/login')
      localStorage.setItem('logout_message', 'You are now logged-out.')
    },
    tabSwitch (val) {
      const element = document.querySelectorAll('input')
      if (element[val - 1].value !== '' && val !== 6) {
        element[val].focus()
      } else if (element[val - 1].value === '' && val !== 1) {
        element[val - 2].focus()
      }
    },
    getCode (e, index) {
      e.preventDefault()
      const pastedValue = (e.clipboardData || window.clipboardData).getData('Text')
      this.otp_code = pastedValue.split('').slice(0, 6)
      this.$refs.verifyBtn.$el.focus()
    },
    async verifyOTP () {
      const otpCodeString = this.otp_code.join('')
      this.hasProcessOTP = true
      if (otpCodeString.length < 6) {
        renderToast('error', 'Invalid Code', 'The OTP Code is Invalid. The code must be 6-digits.')
        return
      }
      const payload = {
        user_name: this.currUser.user_name,
        otp_code: otpCodeString,
        pin: this.currUser.otp_pin,
        module: 'login'
      }
      this.$store.commit('SET_LOADING', true)
      try {
        const res = await this.$store.dispatch('verifyOTP', payload)
        this.$store.commit('SET_LOADING', false)
        if (res?.result && res.result !== false) {
          const currentUserData = { ...res.result, ...this.currUser }
          this.$store.commit('SET_CURRENT_USER', currentUserData)
          this.$store.commit('SET_OTP_PASSED', true)
          this.$store.commit('SET_AUTHENTICATED', true)
          console.log('[DEBUG] User Group ID:', currentUserData.user_group_id)
          const isAssigned = await this.checkUserAssignment()
          if (isAssigned) {
            this.completeLogin()
          } else {
            this.relieverDialog = true
          }
        } else {
          throw new Error('Invalid OTP')
        }
      } catch (err) {
        this.$store.commit('SET_LOADING', false)
        this.otp_code = ['', '', '', '', '', '']
        renderToast('error', 'OTP Failed', 'Invalid or expired OTP.')
        this.$refs.otpTxt1?.$el?.focus()
      }
    },
    async checkUserAssignment () {
      const tellerGroups = [
        '3', '5', '6', '15', '16', '17', '18', '20', '21', '22', '23', '30', '31', '34',
        '42', '44', '45', '46', '47', '48', '49', '50', '52', '54', '55', '56', '57',
        '58', '59', '60', '61', '62', '63', '64', '69', '73', '74', '78', '80', '82', '83',
        '84', '85', '87'
      ]
      if (!tellerGroups.includes(String(this.currUser.user_group_id))) return true
      try {
        const response = await axios.post('https://apiman.perahub.com.ph/uat/uspdata/v1/usp-maintenance/collections/users-grid', {
          location_id: this.$store.state.l1
        }, {
          headers: {
            'x-perahub-key': 'eMztFvKCKucWzVDkcrusCLAGgP387zrVtULhrct9XbIuDWBL',
            Authorization: `Bearer ${this.currUser.token}`
          }
        })
        const users = response.data.result.data
        return users.some(user => user.user_id === this.currUser.user_id)
      } catch (error) {
        console.error('User assignment check failed:', error)
        return false
      }
    },
    completeLogin () {
      const userData = { ...this.currUser }
      const token = userData.token
      const timestamp = this.authTimestamp
      delete userData.token
      delete userData.modules
      this.$cookies.set('USP_DATA', aesEncrypt(JSON.stringify(userData)))
      this.$cookies.set('USP_TOKEN', aesEncrypt(JSON.stringify(token)))
      this.$cookies.set('USP_AUTH_TIMESTAMP', aesEncrypt(JSON.stringify(timestamp)))
      location.href = this.$uspMainAppURL
    },
    confirmReliever () {
      const payload = {
        user_id: this.currUser.user_id,
        location_id: this.$store.state.l1,
        trx_date: format(new Date(), 'yyyy-MM-dd')
      }
      axios.post('https://apiman.perahub.com.ph/uat/usp-auth/v1/api/machine/reliever', payload, {
        headers: {
          'x-perahub-key': 'eMztFvKCKucWzVDkcrusCLAGgP387zrVtULhrct9XbIuDWBL'
        }
      }).then(res => {
        if (res.data.code === 200) {
          renderToast('success', 'Reliever Confirmed', res.data.message)
          this.relieverDialog = false
          this.completeLogin()
        } else {
          renderToast('error', 'Reliever Error', res.data.message)
        }
      }).catch(err => {
        console.error('Reliever API error:', err)
        renderToast('error', 'Reliever Error', 'Could not update reliever status.')
      })
    },
    cancelReliever () {
      renderToast('info', 'Cancelled', 'You have been logged out.')
      this.$store.commit('SET_CURRENT_USER', null)
      this.$store.commit('SET_AUTHENTICATED', false)
      this.$router.push('/login')
    }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

  .ph-otp-input-container {
    display: flex;
    flex-direction: row;
  }

  .ph-otp-textbox {
    margin: 0 4px !important;
  }

  @media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }

    .ph-otp-textbox {
      margin: 0 9px !important;
    }
  }
</style>

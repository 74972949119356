<template>
  <v-container fluid>
    <v-row class="login-container text-center full-height">
      <v-col class="login-container-card" cols="12">
        <v-col class="login-card">
          <div class="branding-container">
            <div class="branding">
              <img class="login-logo" src="@/assets/images/ph-logo-horizontal.svg" />
              <p class="login-system-title">Unified Service Portal</p>
            </div>
          </div>
          <br>
          <div class="ph-field-container">
            <div class="p-col-12 p-d-flex p-flex-column p-ai-center p-jc-center">
              <div class="login-page-icon-container">
                <img class="login-page-icon" v-bind:src="pageIconURL" />
              </div>
              <h1 class="reset-password-title">Lightapp is not detected</h1>
              <p class="reset-password-subtitle">The system was unable to establish a secure connection with the lightapp</p>
              <v-col fluid cols="12" class="ph-action-buttons">
                <v-col fluid cols="12" class="ph-action-button">
                  <v-btn x-large color="#1A2791" elevation="0" class="ph-request-reset-button ph-login-button ph-login-reset-btn" @click="checkMachineStatus()">
                    Retry
                  </v-btn>
                </v-col>
              </v-col>
            </div>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'ForgetPassword',
  data () {
    return {
      pageIconURL: require('@/assets/images/icons/lock-icon.svg')
    }
  },
  beforeUnmount () {
    this.checkMachineStatus2()
  },
  methods: {
    async checkMachineStatus () {
      this.$store.commit('SET_LOADING', true)
      const url = 'https://petnetusp.perahub.com.ph:8085/GetTerminal'
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) throw new Error('Request failed')

        const data = await response.json()

        switch (data._messageCode) {
          case '000':
            this.$router.push('/login')
            break
          case '001':
          case '002':
            this.$router.push('/machine-register')
            break
        }
      } catch (error) {
        this.openErrorWindow(url)
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    async checkMachineStatus2 () {
      this.$store.commit('SET_LOADING', true)
      const url = 'https://petnetusp.perahub.com.ph:8085/GetTerminal'
      try {
        const response = await fetch(url, { method: 'GET' })
        if (!response.ok) throw new Error('Request failed')

        const data = await response.json()

        switch (data._messageCode) {
          case '000':
            this.$router.push('/login')
            break
          case '001':
          case '002':
            this.$router.push('/machine-register')
            break
        }
      } catch (error) {
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    openErrorWindow (url) {
      this.popupWindow = window.open(url, 'ErrorWindow', 'width=1000,height=800')
      const checkPopupClosed = setInterval(() => {
        if (this.popupWindow && this.popupWindow.closed) {
          clearInterval(checkPopupClosed)
          window.location.reload()
        }
      }, 1000)
    }
  }
}
</script>
<style scoped>
  .login-logo {
    margin: auto;
    margin-bottom: 0;
    height: 25px;
    width: auto;
  }

  .login-inputs {
    margin-bottom: 25px;
  }

  .login-container-card {
    text-align: center;
  }

  .login-system-title {
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 11.6px;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    bottom: 5px;
  }

  .branding {
    margin: auto;
    margin-top: 30px;
    height: 50px;
  }

  .branding-container {
    display: flex;
    margin-top: 0;
  }

  .reset-password-title {
    font-family: 'Baloo Extra Bold';
    font-size: 24px;
    font-weight: bolder;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #1A2791;
  }

  .reset-password-subtitle {
    font-size: 14px;
  }

  .ph-login-button {
    background: #FFF;
    color: #1A2791;
    /* border: 2px solid #1A2791; */
    border-radius: 10px;
    font-family: 'Proxima Nova';
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
    padding: 23px;
    width: 100%;
    transition: .2s;
    height: 60px !important;
  }

  .ph-login-button:hover {
    background: #1A2791 !important;
    color: #fff !important;
    /* border: 2px solid #1A2791; */
  }

  .ph-login-button.ph-login-reset-btn {
    background: #1A2791;
    color: #fff;
    box-shadow: -4.75px 30px 30px 0px #1A279140 !important;
    font-size: 14px;
    z-index: 1;
  }

  .login-container-card .ph-back-button {
    color: #1A2791;
    display: flex;
    margin-top: 30px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .ph-back-logo {
    margin-right: auto;
  }

  .ph-back-text {
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Proxima Nova Bold" !important;
    text-decoration: none;
    font-size: 14px;
  }

  .ph-action-button {
    padding-top: 20px;
  }

  .ph-action-button,
  .ph-back-button {
    padding-left: 0;
    padding-right: 0;
  }

  .ph-mail-disclaimer {
    margin-top: 70px;
    font-size: 14px;
  }

  .footer {
    position: relative;
    top: 15px;
    font-size: 12px;
    text-align: center;
    margin: auto;
  }

  .ph-textbox-container {
    padding-top: 15px;
    padding-bottom: 0;
  }

  .login-card {
    margin: auto;
  }

@media only screen and (min-width: 960px) {
    .branding-container {
      height: 100%;
      margin-top: 50px;
    }

    .login-logo {
      height: 30px;
    }

    .login-system-title {
      font-size: 14px;
      position: relative;
      bottom: 5px;
    }

    .branding {
      margin-top: auto;
    }

    .footer {
      position: relative;
      top: 100px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 500px) {
    .login-card {
      margin: auto;
      text-align: center;
      width: 395px;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
    }
  }
</style>
